.fotorama{
  width: 100%;
}

.fotorama__caption__wrap__0__wrapper{
  padding-top:5%;
}

.fotorama__caption{
  padding-left:10%;
}

.fotorama__arr {
  opacity: 1 !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

.fotorama__arr:hover {
  background-color: #0b418b;
}

.fotorama__arr--disabled {
  opacity: .1 !important;
}

.fotorama__arr--prev{
  background-color: grey;
  border-radius: 50%;
}

.fotorama__arr--next{
  background-color: grey;
  border-radius: 50%;
}
.fotorama__wrap--rtl .fotorama__caption{
  left:5%;
  right:60%;
  display:flex;
  flex-direction: column;
  text-decoration: none;
}

.fotorama__caption{
  position: absolute;
  z-index: 12;
  bottom:5%;
  right:30%;
  font-size: 18px;
  text-align: left;
}
.fotorama__caption a{
  color: #0B418B;
  border-bottom: none;
}
.fotorama__caption a:visited{
  color:#551A8B;
}

.fotorama__caption__wrap__0{
  text-align: left;
  text-decoration: none;
  background-color: white;
  padding: 5px 10px;
}

.fotorama_captionwrap_0{
  text-align: left;
}

@media screen and (max-width: $max-width-1) {

  .fotorama__caption{
    font-size:12px;
  }

  .fotorama__caption__wrap__0{
    font-size:12px;
  }

}
