html{
overflow-x:hidden;
}

body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  display:flex;
  min-height:100vh;
  width:100%;
  background: url(/assets/images/nav/bg-start.jpg) no-repeat top center transparent;
  flex-direction: column;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

a{
  color: #0B418B;
  text-decoration: none;
}
a:hover{
  color:#555;
}
a:visited{
  color:#551A8B;
}

h2{
  /*padding-left:10%;*/
  font-size:20px;
}

.main_content_wrapper{
  width:100%;
  height:100%;
  padding-top:130px;
  display: flex;
  justify-content: center;
  background: url(/assets/images/logo/wappen.gif) no-repeat center 580px transparent;
}

.img-logo {
  height: 44px;
  width: 44px;
  vertical-align: sub;
}
img {
  max-width: 100%;
}
ul {
  list-style-type: disc;
}
/* Hide bullets */
.ul_no_bullet{
  list-style-type: none;
}
/* https://www.w3schools.com/howto/howto_css_image_grid_responsive.asp */
.row {
  display: flex;
}

/* Create four equal columns that sits next to each other */
.column {
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  /*max-width: 200px;*/
}

.main_content_h1{
  display: flex; 
  align-items: center;
  /*width: 100%;*/
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
}

notice{
  display:flex;
  flex-direction: column;
  background-color: #e3d52e;
  border:1px solid #CCC;
}

.main_content_body_wrapper{
  background-color: white;
  padding:30px 30px 50px 30px;
  max-width: $max-width-1;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  border:1px solid #CCC;
  min-width: $max-width-1;
}

.main_content_body_bot_container{
  display:flex;
  padding-top: 5%;
}

eventpreview, gallerypreview {
  display:flex;
  flex-direction: column;
  background-color: #EFEFEF;
  padding-bottom:2%;
  border:1px solid #CCC;
  width:100%;
}
eventpreview ul {
  list-style-type: none;
}

.event_home{
  line-height: 1.75;
}

.event_date_home{
  padding: 5px 10px 5px 10px;
  background-color: #0B418B;
  text-decoration:none;
  cursor: pointer;
  color: white!important;
  width: 60px;
  display: inline-block;
  margin: 4px 0 4px 0;
}
/*.event_date_home:hover{
  color: white;
}*/
/*.event_home:hover{
  background-color: #0e3872;
  color: white;
}*/
.event_description_home{
  color:black!important;
/*  color:#515151;*/
  text-decoration:none;
  padding-left:30px;
  word-wrap: break-word;      /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap;      /* current browsers */
  word-wrap: break-word; 
}
/*.event_description_home:hover{
  color:white;
}*/
/*.event_description_home:visited{
  color: black;
}*/

.body_bot_container_h2_wrapper{
  width:100%;
  padding-left:5%;
}

gallerypreview{
  display:flex;
  flex-direction: column;
  width:fit-content;
}
.main_content_body_bot_container_action_button{
  padding-left:5%;
  display:flex;
  justify-content: left;
  padding-top: 10px;
}

.bot_action_btn{
  font-family: 'Helvetica', Arial, sans-serif;
  background-color: #0b418b;
  color: white !important;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  text-align: center;
  padding: 5px 20px 5px 20px;
  text-decoration: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: $max-width-1) {

  body{
    width: auto;
    margin-left: 8px;
    margin-right: 8px;
  }

  .main_content_body_wrapper{
    min-width: auto;
  }

  .fotorama__wrap--rtl .fotorama__caption{
    font-size: 14px;
  }

  .column {
    flex: 20%;
    max-width: 50%;
  }

  .main_content_wrapper{
    background:none;
    flex-direction: column;
    padding-top: 60px;
  }


  .main_content_body_bot_container{
    flex-direction: column;
  }

  .bot_action_btn{
    font-size: 12px;
  }
  eventpreview{
    width:auto;
  }
}

@media screen and (max-width: $max-width-2) {
  .fotorama__wrap--rtl .fotorama__caption{
    font-size: 12px;
  }

  .main_content_body_wrapper{
    min-width: auto;
  }

}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $max-width-3) {
  .column {
    flex: 0%;
    max-width: 100%;
  }
  .bot_action_btn{
    font-size:12px;
  }

  .fotorama__wrap--rtl .fotorama__caption{
    font-size: 8px;
  }

  .main_content_body_wrapper{
    min-width: none;
  }
}

.main_content_body_top_container{
  display:flex;
  flex-direction: column;
}

.notice_class_wrapper{
  display: flex;
}

.h2_notice{
  margin-bottom: 0px;
  margin-top: 10px;
}

.h3_notice{
  margin-bottom: 0px;
  margin-top: 0px;
}

.a_nav_back{
  color: blue;
  padding-bottom: 15px;
}

.separator_class{
  display:flex;
  flex-direction: row;
}

@media screen and (max-width: $max-width-1) {
  .separator_class{
    flex-direction: column-reverse;
  }

  .seperator_1{
    padding-left:0%;
    border:none;
  }
}

.notice_no_events{
  padding-left: 5%;
}

figure figcaption {
  font-size: 14px;
  text-align: center;
}

figure img{
  margin-left: 50%;
  transform: translateX(-50%);
}

#gallerypreview_id {
  padding: 0px 15px 0px 15px;
}
