.eventgalerie_main_wrapper{
  display:flex;
  width:100%;
}

.figcaption_gallery_index{
  padding-top: 5px;
  font-size: inherit;
}

.eventgalerie_subnav_wrapper{
  display:flex;
  /*padding-top:5%;*/
  justify-content: center;
  padding-left:10%;
}

.gallery_back_wrapper{
  display:none;
  justify-content: left;
  padding-bottom: 1%;
}

#gallery_back_btn_id{
  background-color: #0b418b;
  width:50px;
  height:50px;
  cursor:pointer;
  color:white;
}

.fotorama_galerie_class{
  width: 100%;
}

.row_sub{
  display:flex;
  flex-flow: row wrap;
  align-content: space-between;
  align-items: center;
}

.column_sub{
  width: 250px;
  cursor: pointer;
  height: 250px;
  font-size: 30px;
  line-height: 100px;
  text-align: center;
  margin: 10px;
}

.hide_array{
  display:none;
}

.row a{
  color:black;
}

@media screen and (max-width: $max-width-2) {
  .eventgalerie_main_wrapper{
    justify-content: center;
  }
}

.eventgalerie_main_wrapper .row{
  display:flex;
  flex-flow: row wrap;
  align-content: space-between;
  align-items: center;
}
.eventgalerie_main_wrapper .column{
  width: 22%;
  cursor: pointer;
  height: 320px;
  padding: 0 10px;
  max-width: 80%;
}

@media screen and (max-width: $max-width-2) {
  .eventgalerie_main_wrapper .row{
    flex-direction: column;
    width: 100%;
    align-content: center;
  }

  .eventgalerie_main_wrapper .column{
    width: 100%;
    cursor: pointer;
    flex:1 1 0%;
    padding:5%;
  }
}

