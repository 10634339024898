.separator_2_ul_no_spacing{
	height: 5%;
}

.semesterprogramm_event_description{
	color: white!important;
}

.semesterprogramm_event_wrapper{
	padding: 2px 20px 20px 20px;
	background-color: #0B418B;
	color:white;
	margin: 4px 0 4px 0;
}
.event_date{
	padding:0%;
}

.event_date:hover,event_location:hover,.event_description:hover{
	background-color: #0B418B;
}

.semesterprogramm_event_Wrapper{
	cursor: pointer;
}

.event_ext{
	display:flex;
	flex-direction: column;
}

.event_description:hover{
	color:black;
}

.separator_1{
	padding-right:5%;
	padding-left:0%;
	padding-left:0px;
	border-right:1px solid black;
}

.separator_2{
	padding-left:5%;
	width:100%;
}

@media screen and (max-width: $max-width-1) {
	.separator_1{
		padding-right:0%;
		padding-left:0%;
		border-right:0px solid black;
	}
	.separator_2{
		padding-left: 0%;
		width: 100%;
	}

	.separator_1 ul{
		padding-left: 0px;
	}
	.separator_2 ul{
		padding-left: 0px;
	}

	h2{
		padding-left: 0%;
	}
}

@media screen and (max-width: $max-width-2) {
	.separator_1{
		padding-right: 0;
		border-right:0px solid black;
	}
	.separator_2{
		padding-left: 0%;
		width: 100%;
	}
}

.separator_1 ul{
	list-style-type: none;
}

.separator_2 ul{
	list-style-type: none;
	padding-left: 30px;
	padding-right: 30px;
}

.semesterprogramm_event_wrapper ul{
	padding-left: 0;
}

.month_long{
	font-weight: bold;
	padding-bottom: 4px;
}

event_title a {
	display: flex;
}
date {
	float: left;
	padding-right: 5px;
}
