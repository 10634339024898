.separator_4{
  flex-basis: 80%;
  padding-left:10%;
  padding-right:10%;
  text-align: justify;
  text-justify: inter-word;
}

@media screen and (max-width: $max-width-1) {
  .separator_3{
    padding:0%;
    border:none !important;
  }

  .separator_4{
    padding:0%;
  }
}

.separator_3 a{
  padding:10%;
}

.separator_3{
  padding-right:4%;
  border-right: 1px solid black;
  flex-basis: 25%;
}

nav{
  display:flex;
  flex-direction: column;
}

.subnav_item{
  padding:10%;
  cursor:pointer;
  background-color: #EFEFEF;
  width:85%;
  color:#555;
  font-weight: bold;
}

.subnav_link{
  width:85%;
  padding:10%;
  color:#0b418b;
  font-weight: bold;
}

.subnav_link:hover{
  background-color: #0b418b;
  color:white;
}

figure_rhr {
  float: left;
  padding-right: 10px;
}
