body{
  overflow-x: hidden;
}

/* MAIN NAVIGATION */

.nav_item_right {
  border-right: none !important;
}

.wrapper_nav_item ul {
  list-style-type: none;
  border-right: white 1px solid;
  background-color: #0b418b
}
.wrapper_nav_item li {
  /*padding-top:5px;*/
  line-height: 20px;
  /*border-bottom: #515151 1px solid;*/
}
.wrapper_nav_item li.active a {
  color: #ffffff;
}

#nav_item_active {
  background-color: #0E3872;
}

/* NAVIGATION BAR
-------------------------------------------------- */
.main_nav_wrapper{
  display: flex;
  justify-content: center;
}

.wrapper_nav_item li a {
  color: black !important;
}

.wrapper_nav_item li a:hover,
.wrapper_nav_item li a:focus {
  color: white;
}

.dropdown_content_wrapper a{
  display:flex;
  width:100%;
  padding:5%;
}


.wrapper_nav_item .active a {
  color: #ffffff !important;
  border-bottom: 2px solid #ffffff !important;
}

.wrapper_nav_item .dropdown-menu li a {
  color: #515151 !important;
  text-shadow: none !important;
}

.wrapper_nav_item{
  display:flex;
  flex-direction: row;
  justify-content: right;
  margin-top: -1.5%;
  height:max-content;
  width:100%;
  max-width:865px;
  position: relative;
  flex-wrap: wrap;
  flex:75%;
  background-color: #0b418b;
}

.nav_item{
  padding-left:0px;
  padding-top:0%;
  padding-right:0px;
  cursor:pointer;
  display:flex;
  margin-bottom: 0px;
}

.dropdown_content_wrapper{
  padding-top:30px;
  display: flex;
  flex-direction: row-reverse;
  margin-left:25%;
}

.dropdown_toggle:hover .dropdown_menu {
  display: block;
}

.dropdown_toggle {
  padding-right: 6px !important;
}

.dropdown_menu{
  display:none;
  position:absolute;
  background-color: white;
  border: 1px solid #CCC;
  z-index: 1;
  padding-left:0px;
  width:20%;
  transform: translateX(-25px);
}

.dropdown_menu li:hover{
  background-color: #EFEFEF;
}

.dropdown_menu li{
  background-color: white;
}
/* Top Nav  */
.main_nav{
  display:flex;
  flex-direction: row;
  top:0;
  position:absolute;
  width:100%;
  max-width: $max-width-1 + 60px;
  padding: 0px 0px 0px 0px;
}

.wrapper_nav_item{
  color: white;
  display: flex;
}
.wrapper_nav_item a{
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.wrapper_dropdown_caret i{
  padding-left: 5px;
}

.wrapper_brand_image{
  width:200px;
}

.wrapper_brand_image a {
  padding:0px;
  display:flex;
}

.brand_image {
  cursor:pointer;
  background-color: #0b418b;
}

.mobile_nav_outer_wrapper{
  color: white;
  position: absolute;
  top:0;
  display:none;
  justify-content: right;
  z-index: 0;
}

.mobile_nav_outer_wrapper a {
  color: white;
}

.mobile_nav_inner_wrapper{
  position: fixed;
  display: flex;
  font-size:15px;
  right:-15px;
}

.mobile_nav_inner_wrapper_normal{
  position: fixed;
  display: flex;
  font-size:15px;
  right:-215px;
}

.mobile_nav_inner_wrapper_ios{
  position: fixed;
  display: flex;
  font-size:15px;
  right:-245px;
}

.mobile_height{
  height:100%;
}

.move_mobile_nav_wrapper{
  right:-25px;
  /*transition: all 1s ease;*/
  z-index: 10;
}

.mobile_nav_wrapper_extraHeightClass{
  height:100%;
}

.mobile_nav_content{
  background-color: #0b418b;
  display: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width:200px;
}

.mobile_nav_content h3{
  font-weight: bold;
}

.mobile_nav_content a{
  padding:0px;
}

.mobile_nav_btn_1{
  border:none;
  text-decoration: none;
  color: inherit;
  background-color: #0b418b;
  cursor: pointer;
}

.mobile_nav_btn_1 i{
  font-size:40px;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.nav_item{
  background-color: #0b418b;
  float:left;
}

.nav_item:hover{
  background-color:#0E3872 ;
}

@media screen and (max-width: $max-width-1) {
  .wrapper_brand_image{
    width:120px;
    padding-top:5px;
  }

  .mobile_nav_content{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
    padding-right:5%;
    margin-right: 1em;
    padding-left:5%;
  }

  .mobile_nav_content ul{
    padding-left:10%;
    list-style-type: none;
  }

  .mobile_nav_outer_wrapper{
    display:block;
    overflow: hidden;
    z-index: 999999999;
    padding-top: 5px;
  }

  .main_nav{
    padding-left:0px;
    width:90%;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    height:10.5%;
    left: 8px;
  }

  .wrapper_nav_item{
    display: none;
  }
}

@media screen and (max-width: $max-width-2) {
  .brand_image{
    width:100%;
  }

  .main_nav{
    position: absolute;
  }

  .mobile_nav_wrapper{
    display:flex;
  }

  .mobile_nav_content{
    display:flex;
  }
}
