.footer_main_link{
  color: white;
}
  
.footer_nav_subnav_wrapper{
  width:115%;
  display: flex;
  flex-direction: column;
  padding-top:10%;
  list-style-type: none;
}
.footer_nav_subnav_wrapper a{
  color: #959696;
  padding:0px;
}

.footer_nav_main_wrapper a{
  padding:0px;
}
.footer_main_link:visited{
  color: white;
}
.footer_nav_subnav_wrapper a:hover{
  color: white;
}

.footer_nav_subnav_wrapper li{
  padding:1%;
}

.footer_nav_subnav_wrapper li:hover{
  color: white;
}

.footer{
  display:flex;
  justify-content: center;
}

.footer_content{
  background-color: #222;
  color: #959696;
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: $max-width-1;
  padding:0px 30px 0px 30px;
}
.footer_top{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-bottom:3%;
  padding-top:3%;
}
.footer_bottom{
  display:flex;
  justify-content: space-evenly;
  width:100%;
}

.footer_nav_main_wrapper{
  white-space: nowrap;
}
@media screen and (max-width: $max-width-1) {
  .footer{
    padding:0px;
  }
  .footer_top{
    font-size: 12px;
    flex-direction: row;
    flex:1;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .footer_nav_main_wrapper{
    padding:3%;
  }

}

@media screen and (max-width: $max-width-2) {
  .footer_top{
    font-size:12px;
  }
}
